/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import { DataGrid } from '@mui/x-data-grid';

import PropTypes from 'prop-types';

import api from '../../../services/api';

import './styles.css';

export default function BeaconsCard({
  scrollToMapComponent,
  points,
  setPoints,
  setAudioFile,
  setImageFile,
  setFormData,
  setFormEditData,
  selectedCoords,
  setSelectedCoords,
  setSelectedPoint,
}) {
  const [beacons, setBeacons] = useState([]); // Estado para armazenar a lista de beacons

  useEffect(() => {
    // Função para buscar todos os beacons do banco de dados
    const fetchBeacons = async () => {
      try {
        const response = await api.get('/beacons');
        setBeacons(response.data); // Atualiza o estado com a lista de beacons
      } catch (err) {
        console.error(err);
      }
    };

    fetchBeacons();
  }, []); // Executa a busca dos beacons apenas uma vez, na montagem do componente

  const onlyBeacons = points.filter((point) => point.type === 'beacon');

  // Função para obter o nome do beacon a partir do seu ID
  const getBeaconNameById = (beaconId) => {
    const beacon = beacons.find((b) => b.id === beaconId);
    return beacon ? beacon.name : '';
  };

  const handleDeletePoint = async (pointObj) => {
    try {
      const deleteAudioFilePromise = pointObj.audioUrl ? api.delete(`/points/file/audio/${pointObj.id}`) : null;
      const deleteImageFilePromise = pointObj.imageUrl ? api.delete(`/points/file/image/${pointObj.id}`) : null;
      const deletePointPromise = api.delete(`/points/${pointObj.id}`);

      // ask for confirmation before delete
      const confirmation = window.confirm('Tem certeza que deseja excluir este ponto?');

      if (!confirmation) return;

      await Promise.allSettled([
        deleteAudioFilePromise,
        deleteImageFilePromise,
        deletePointPromise,
      ]);

      setPoints(points.filter((point) => point.id !== pointObj.id));
      setAudioFile(null);
      setImageFile(null);
      setFormData({
        type: 'gps',
        name: '',
        category: '',
        description: '',
        radius: 10,
        beacon: '',
        isVisible: true,
        isAutomaticallyPlayed: true,
        audioUrl: '',
        imageUrl: '',
      });
      setFormEditData({
        id: '',
        name: '',
        categoryId: '',
        category: '',
        description: '',
        type: 'gps',
        radius: 10,
        beacon: '',
        isVisible: true,
        isAutomaticallyPlayed: true,
        audioURL: '',
        imageURL: '',
      });
      setSelectedCoords({
        lat: null,
        lng: null,
      });
      setSelectedPoint(null);
      alert('Ponto excluído com sucesso!');
    } catch (err) {
      console.error(err);
    }
  };

  const renderCellActions = (params) => (
    <DeleteIcon
      color="#414141"
      size={25}
      onClick={(e) => {
        e.stopPropagation();
        handleDeletePoint(params.row);
      }}
      style={{
        cursor: 'pointer',
      }}
    />
  );

  const renderName = (params) => {
    const handleNameClick = () => {
      if (!selectedCoords.lat && !selectedCoords.lng) {
        setSelectedPoint(params.row);
        setFormEditData({
          id: params.row.id,
          type: params.row.type,
          name: params.row.name,
          categoryId: params.row.categoryId,
          category: params.row.category,
          description: params.row?.description,
          radius: params.row.radius,
          beacon: params.row.beacon,
          isVisible: params.row.isVisible,
          isAutomaticallyPlayed: params.row.isAutomaticallyPlayed,
          audioURL: params.row.audioURL,
          imageURL: params.row.imageURL,
          linkURL: params.row.linkURL,
          linkTitle: params.row.linkTitle,
        });
        scrollToMapComponent();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleNameClick();
      }
    };

    return (
      <span
        onClick={handleNameClick}
        onKeyDown={handleKeyDown} // Adicione este ouvinte de teclado
        role="button" // Adicione o papel do elemento
        tabIndex={0} // Torne o elemento focável
        style={{
          cursor: 'pointer',
          color: '#414141',
        }}
      >
        {params.row.name}
      </span>
    );
  };

  const columns = [
    {
      field: 'action',
      headerName: '#',
      sortable: false,
      headerAlign: 'center',
      disableColumnMenu: true, // disable column menu
      renderCell: renderCellActions,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Nome',
      headerAlign: 'center',
      renderCell: renderName,
      flex: 2,
    },
    {
      field: 'category',
      headerName: 'Categoria',
      headerAlign: 'center',
      flex: 3,
    },
    {
      field: 'beaconId',
      headerName: 'Beacon',
      headerAlign: 'center',
      valueGetter: (params) => getBeaconNameById(params.value),
      flex: 3,
    },
  ];

  const dataGridStyles = {
    mt: 2,
    border: 'none',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-row': {
      borderBottom: 'none',
    },
    '& .MuiDataGrid-rowSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-cell': {
      // borderBottom: 'none',

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  };

  return (
    <div className="points-card">
      <span className="points-title">
        <h1>Beacons</h1>
      </span>
      <DataGrid
        sx={dataGridStyles}
        autoHeight
        rows={onlyBeacons}
        columns={columns}
        rowsPerPageOptions={[5]}
        pageSize={5}
        disableSelectionOnClick
      />
    </div>
  );
}

BeaconsCard.propTypes = {
  scrollToMapComponent: PropTypes.func.isRequired,
  points: PropTypes.arrayOf(
    PropTypes.object.isRequired,
  ).isRequired,
  setPoints: PropTypes.func.isRequired,
  setAudioFile: PropTypes.func,
  setImageFile: PropTypes.func,
  setFormData: PropTypes.func,
  setFormEditData: PropTypes.func,
  setSelectedCoords: PropTypes.func,
  selectedCoords: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  setSelectedPoint: PropTypes.func,
};
