import axios from 'axios';

import { API_KEY } from '../config/config';

const api = axios.create({
  baseURL: API_KEY,
});

/* Interceptador das requisições. Aqui é definido que todas as
requisições devem conter no cabeçalho a chave de autenticação do JWT */

api.interceptors.request.use(
  async (config) => {
    const userAuthToken = await localStorage.getItem('token');
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${userAuthToken}`;
    return config;
  },
  (err) => {
    Promise.reject(err);
  },
);

export default api;
