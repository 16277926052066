/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import {
  GoogleMap,
  Marker,
  Circle,
  InfoWindowF,
  useLoadScript,
} from '@react-google-maps/api';

import MyLocationIcon from '@mui/icons-material/MyLocation';
import ExploreIcon from '@mui/icons-material/Explore';

import PropTypes from 'prop-types';

import { GOOGLE_KEY } from '../../config/config';
import api from '../../services/api';
import { mapOptions } from './mapOptions';
import { mapLibraries } from './mapLibraries';
import { mapStyles } from './mapStyles';

import userPin from '../../assets/userLocation.png';

import PointRegisterWindow from './PointRegisterWindow';
import PointUpdateWindow from './PointUpdateWindow';

export default function Map({
  address,
  categories,
  points,
  setPoints,
  audioFile,
  setAudioFile,
  imageFile,
  setImageFile,
  formData,
  setFormData,
  formEditData,
  setFormEditData,
  selectedCoords,
  setSelectedCoords,
  selectedPoint,
  setSelectedPoint,
}) {
  const [
    location,
    setLocation,
  ] = useState({
    lat: 37.7749,
    lng: -122.4194,
  });

  const [markedUserLocation, setMarkedUserLocation] = useState({
    lat: null,
    lng: null,
  });

  const handleInputChangeData = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleInputChangeEditData = (e) => {
    const { name, value } = e.target;
    setFormEditData({
      ...formEditData,
      [name]: value,
    });
  };

  const handleCloseRegisterWindow = () => {
    setSelectedCoords({
      lat: null,
      lng: null,
    });
    setFormData({
      type: 'gps',
      name: '',
      category: '',
      description: '',
      radius: 10,
      beacon: '',
      isVisible: true,
      isAutomaticallyPlayed: true,
      audioURL: '',
      imageURL: '',
      linkURL: '',
      linkTitle: '',
    });
    setAudioFile(null);
    setImageFile(null);
  };

  const handleCloseUpdateWindow = () => {
    setSelectedPoint(null);
    setFormEditData({
      id: '',
      type: 'gps',
      name: '',
      categoryId: '',
      category: '',
      description: '',
      radius: 10,
      beacon: '',
      isVisible: true,
      isAutomaticallyPlayed: true,
      audioURL: '',
      imageURL: '',
      linkURL: '',
      linkTitle: '',
    });
    setAudioFile(null);
    setImageFile(null);
  };

  const handleOnCloseInfoWindow = () => {
    handleCloseRegisterWindow();
    handleCloseUpdateWindow();
  };

  const handleMapClick = (e) => {
    if (!selectedPoint) {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      setSelectedCoords({
        lat,
        lng,
      });
    }
  };

  const handleMarkerClick = (point) => {
    if (!selectedCoords.lat && !selectedCoords.lng) {
      setSelectedPoint(point);
      setFormEditData({
        id: point.id,
        type: point.type,
        name: point.name,
        categoryId: point.categoryId,
        category: point.category,
        description: point.description,
        radius: point.radius,
        beacon: point.beaconId,
        isVisible: point.isVisible,
        isAutomaticallyPlayed: point.isAutomaticallyPlayed,
        audioURL: point.audioURL,
        imageURL: point.imageURL,
        linkURL: point.linkURL,
        linkTitle: point.linkTitle,
      });
    }
  };

  const handleMarkerMove = async (e, pointId) => {
    if (!selectedCoords.lat && !selectedCoords.lng) {
      const updatedPoints = points.map((point) => (
        point.id === pointId
          ? {
            ...point,
            latitude: e.latLng.lat().toString(),
            longitude: e.latLng.lng().toString(),
          }
          : point
      ));
      setPoints(updatedPoints);

      try {
        const pointToUpdate = updatedPoints.find((point) => point.id === pointId);
        if (pointToUpdate) {
          await api.patch(
            `/points/${pointId}`,
            {
              latitude: pointToUpdate.latitude,
              longitude: pointToUpdate.longitude,
            },
          );
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const data = {
      type: formData.type,
      name: formData.name,
      latitude: Number(selectedCoords.lat),
      longitude: Number(selectedCoords.lng),
      description: formData.description,
      categoryId: formData.category,
      radius: formData.type === 'beacon' ? 0 : formData.radius,
      beaconId: formData.type === 'beacon' ? formData.beacon : undefined,
      isVisible: formData.isVisible,
      isAutomaticallyPlayed: formData.isAutomaticallyPlayed,
      linkURL: formData.linkURL,
      linkTitle: formData.linkTitle,
    };

    if (data.type === 'beacon' && !data.beaconId) {
      alert('Selecione um beacon');
      return;
    }

    if (!data.categoryId) {
      alert('Selecione uma categoria');
      return;
    }

    const formDataAudioObj = new FormData();
    formDataAudioObj.append('files', audioFile);

    const formDataImageObj = new FormData();
    formDataImageObj.append('files', imageFile);

    try {
      const registerPointResponse = await api.post('/points', data);

      const formattedResponse = {
        id: registerPointResponse.data.id,
        name: registerPointResponse.data.name,
        latitude: registerPointResponse.data.latitude,
        longitude: registerPointResponse.data.longitude,
        description: registerPointResponse.data.description,
        isActive: registerPointResponse.data.isActive,
        createdAt: registerPointResponse.data.createdAt,
        updatedAt: registerPointResponse.data.updatedAt,
        categoryId: registerPointResponse.data.category.id,
        category: registerPointResponse.data.category.name,
        type: registerPointResponse.data.type,
        radius: registerPointResponse.data.radius,
        beaconId: registerPointResponse.data.beaconId,
        isVisible: registerPointResponse.data.isVisible,
        isAutomaticallyPlayed: registerPointResponse.data.isAutomaticallyPlayed,
        linkURL: registerPointResponse.data.linkURL,
        linkTitle: registerPointResponse.data.linkTitle,
        audioURL: '',
        imageURL: '',
      };

      if (audioFile) {
        const registerAudioResponse = await api.patch(
          `/points/file/audio/${registerPointResponse.data.id}`,
          formDataAudioObj,
        );
        formattedResponse.audioURL = registerAudioResponse.data.audioURL;
      }

      if (imageFile) {
        const registerImageResponse = await api.patch(
          `/points/file/image/${registerPointResponse.data.id}`,
          formDataImageObj,
        );
        formattedResponse.imageURL = registerImageResponse.data.imageURL;
      }

      setPoints([...points, formattedResponse]);

      alert('Ponto registrado com sucesso');

      setFormData({
        type: 'gps',
        name: '',
        category: '',
        description: '',
        radius: 10,
        beacon: '',
        isVisible: true,
        isAutomaticallyPlayed: true,
        audioURL: '',
        imageURL: '',
        linkURL: '',
        linkTitle: '',
      });
      setSelectedCoords({
        lat: null,
        lng: null,
      });
      setAudioFile(null);
      setImageFile(null);
    } catch (err) {
      console.error(err);
      alert('Falha ao registrar ponto, tente novamente');
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const data = {
      name: formEditData.name,
      latitude: Number(selectedPoint.latitude),
      longitude: Number(selectedPoint.longitude),
      description: formEditData.description,
      categoryId: formEditData.categoryId,
      type: formEditData.type,
      radius: formEditData.radius,
      beaconId: formEditData.type === 'beacon' ? formEditData.beacon : undefined,
      isVisible: formEditData.isVisible,
      isAutomaticallyPlayed: formEditData.isAutomaticallyPlayed,
      linkURL: formEditData.linkURL,
      linkTitle: formEditData.linkTitle,
    };

    const formDataAudioObj = new FormData();
    formDataAudioObj.append('files', audioFile);

    const formDataImageObj = new FormData();
    formDataImageObj.append('files', imageFile);
    try {
      const updatePointResponse = await api.patch(`/points/${formEditData.id}`, data);

      const formattedResponse = {
        id: updatePointResponse.data.id,
        name: updatePointResponse.data.name,
        latitude: updatePointResponse.data.latitude,
        longitude: updatePointResponse.data.longitude,
        description: updatePointResponse.data.description,
        isActive: updatePointResponse.data.isActive,
        createdAt: updatePointResponse.data.createdAt,
        updatedAt: updatePointResponse.data.updatedAt,
        category: updatePointResponse.data.category.name,
        categoryId: updatePointResponse.data.categoryId,
        type: updatePointResponse.data.type,
        radius: updatePointResponse.data.radius,
        beaconId: updatePointResponse.data.beaconId,
        isVisible: updatePointResponse.data.isVisible,
        isAutomaticallyPlayed: updatePointResponse.data.isAutomaticallyPlayed,
        linkURL: updatePointResponse.data.linkURL,
        linkTitle: updatePointResponse.data.linkTitle,
        audioURL: '',
        imageURL: '',
      };

      if (audioFile) {
        try {
          const updateAudioResponse = await api.patch(
            `/points/file/audio/${updatePointResponse.data.id}`,
            formDataAudioObj,
          );
          formattedResponse.audioURL = updateAudioResponse.data.audioURL;
        } catch (audioErr) {
          if (audioErr.response && audioErr.response.status === 400) {
            // O erro 400 ao atualizar o áudio é tratado aqui
            // Você pode optar por ignorar o erro ou lidar de outra maneira
            console.warn('Erro ao atualizar áudio, mas ponto foi atualizado com sucesso');
            formattedResponse.audioURL = updatePointResponse.data.audioURL;
          } else {
            throw audioErr; // Se não for um erro 400, propague o erro
          }
        }
      } else {
        try {
          await api.delete(`/points/file/audio/${updatePointResponse.data.id}`);
        } catch (error) {
          console.error(error);
        }
      }

      if (imageFile) {
        try {
          const updateImageResponse = await api.patch(
            `/points/file/image/${updatePointResponse.data.id}`,
            formDataImageObj,
          );
          formattedResponse.imageURL = updateImageResponse.data.imageURL;
        } catch (imageErr) {
          if (imageErr.response && imageErr.response.status === 400) {
            // O erro 400 ao atualizar a imagem é tratado aqui
            // Você pode optar por ignorar o erro ou lidar de outra maneira
            console.warn('Erro ao atualizar imagem, mas ponto foi atualizado com sucesso');
            formattedResponse.imageURL = updatePointResponse.data.imageURL;
          } else {
            throw imageErr; // Se não for um erro 400, propague o erro
          }
        }
      } else {
        try {
          await api.delete(`/points/file/image/${updatePointResponse.data.id}`);
        } catch (error) {
          console.error(error);
        }
      }

      setPoints(points.map((point) => {
        if (point.id === formattedResponse.id) {
          return formattedResponse;
        }
        return point;
      }));

      alert('Ponto atualizado com sucesso');

      setFormEditData({
        id: '',
        name: '',
        categoryId: '',
        category: '',
        description: '',
        type: 'gps',
        radius: 10,
        beacon: '',
        isVisible: true,
        isAutomaticallyPlayed: true,
        audioURL: '',
        imageURL: '',
        linkURL: '',
        linkTitle: '',
      });
      setAudioFile(null);
      setImageFile(null);
      setSelectedPoint(null);
    } catch (err) {
      console.error(err);
      alert('Falha ao atualizar ponto, tente novamente');
    }
  };

  useEffect(() => {
    // Codificar o endereço para usar na URL
    const encodedAddress = encodeURIComponent(address);
    // Construir a URL da solicitação com o endereço e a chave da API
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${GOOGLE_KEY}`;
    // Usar fetch para enviar uma solicitação GET para a API de geocodificação do Google
    fetch(url)
      .then((response) => response.json()) // Converter a resposta em JSON
      .then((data) => {
        // Verificar se há resultados válidos na resposta
        if (data.results && data.results.length > 0) {
          // Extrair as coordenadas do primeiro resultado
          const { lat, lng } = data.results[0].geometry.location;
          /**
           * Fazer algo com as coordenadas, como colocá-las no
           * estado ou usá-las para renderizar o mapa
           */
          setLocation({ lat, lng });
        } else {
          // Lidar com o caso de não haver resultados válidos na resposta
          console.error('No results found for address');
        }
      })
      .catch((error) => {
        // Lidar com o caso de erro na solicitação ou na conversão da resposta em JSON
        console.error(error);
      });
  }, [address]); // Passar o endereço como dependência do useEffect

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GOOGLE_KEY,
    libraries: mapLibraries,
  });

  const [map, setMap] = useState(null);

  const handleMapLoad = (mapRef) => {
    setMap(mapRef);
  };

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading maps';

  const getUserLocation = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;

          setMarkedUserLocation({
            lat: latitude,
            lng: longitude,
          });

          map.panTo({
            lat: latitude,
            lng: longitude,
          });
        },
        (error) => {
          if (error.code === 1) {
            alert('Permissão para localização negada');
            console.error('Permissão para localização negada');
          } else if (error.code === 2) {
            alert('Localização indisponível');
            console.error('Localização indisponível');
          } else if (error.code === 3) {
            alert('Tempo limite de localização esgotado');
            console.error('Tempo limite de localização esgotado');
          } else {
            alert('Erro desconhecido ao obter localização');
            console.error('Erro desconhecido ao obter localização');
          }
        },
      );
    } else {
      alert('Geolocalização não suportada pelo navegador');
      console.error('Geolocalização não suportada pelo navegador');
    }
  };

  const selectUserLocation = () => {
    if (markedUserLocation.lat && markedUserLocation.lng) {
      setSelectedCoords({
        lat: markedUserLocation.lat,
        lng: markedUserLocation.lng,
      });
      setMarkedUserLocation({
        lat: null,
        lng: null,
      });
    }
  };

  return (
    <GoogleMap
      zoom={17}
      center={location}
      options={mapOptions}
      onLoad={handleMapLoad}
      mapContainerStyle={mapStyles}
      onClick={handleMapClick}
    >
      {points.map((point) => (
        <Circle
          key={point.id}
          map={map}
          center={{
            lat: Number(point.latitude),
            lng: Number(point.longitude),
          }}
          radius={point.radius}
          options={{
            fillColor: '#5C257E',
            fillOpacity: 0.2,
            strokeColor: '#5C257E',
            strokeOpacity: 1,
            strokeWeight: 1,
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
            zIndex: 1,
          }}
        />
      ))}
      {points.map((point) => (
        <Marker
          key={point.id}
          map={map}
          title={point.name}
          position={{
            lat: Number(point.latitude),
            lng: Number(point.longitude),
          }}
          draggable={!selectedCoords.lat && !selectedCoords.lng}
          onDragEnd={(e) => handleMarkerMove(e, point.id)}
          onClick={() => handleMarkerClick(point)}
        >
          {selectedPoint && selectedPoint.id === point.id && (
            <InfoWindowF
              position={{
                lat: Number(point.latitude),
                lng: Number(point.longitude),
              }}
              onCloseClick={handleOnCloseInfoWindow}
            >
              <PointUpdateWindow
                selectedPoint={selectedPoint}
                setSelectedPoint={setSelectedPoint}
                formEditData={formEditData}
                setFormEditData={setFormEditData}
                audioFile={audioFile}
                setAudioFile={setAudioFile}
                imageFile={imageFile}
                setImageFile={setImageFile}
                categories={categories}
                handleUpdate={handleUpdate}
                handleInputChangeEditData={handleInputChangeEditData}
              />
            </InfoWindowF>
          )}
        </Marker>
      ))}
      {selectedCoords.lat
        && selectedCoords.lng && (
          <InfoWindowF
            position={{
              lat: selectedCoords.lat,
              lng: selectedCoords.lng,
            }}
            onCloseClick={handleOnCloseInfoWindow}
          >
            <PointRegisterWindow
              selectedCoords={selectedCoords}
              setSelectedCoords={setSelectedCoords}
              formData={formData}
              setFormData={setFormData}
              audioFile={audioFile}
              setAudioFile={setAudioFile}
              imageFile={imageFile}
              setImageFile={setImageFile}
              categories={categories}
              handleRegister={handleRegister}
              handleInputChangeData={handleInputChangeData}
            />
          </InfoWindowF>
      )}
      {!selectedCoords.lat && !selectedCoords.lng && (
        <MyLocationIcon
          sx={{
            fontSize: '2.5rem',
            color: '#666',
            padding: '0.5rem',
            position: 'absolute',
            right: '0.6rem',
            bottom: '12rem',
            zIndex: 2,
            borderRadius: '5%',
            boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
            backgroundColor: '#fff',
            cursor: 'pointer',
            '&:hover': {
              color: '#000',
            },
          }}
          onClick={getUserLocation}
        />
      )}
      <ExploreIcon
        sx={{
          fontSize: '2.5rem',
          color: '#666',
          padding: '0.5rem',
          position: 'absolute',
          right: '0.6rem',
          bottom: '16rem',
          zIndex: 2,
          borderRadius: '5%',
          boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.2)',
          backgroundColor: '#fff',
          cursor: 'pointer',
          '&:hover': {
            color: '#000',
          },
        }}
        onClick={() => {
          handleCloseRegisterWindow();
          handleCloseUpdateWindow();
          map.panTo(location);
        }}
      />
      {markedUserLocation.lat
        && markedUserLocation.lng && (
          <Marker
            map={map}
            title="Sua localização"
            position={{
              lat: markedUserLocation.lat,
              lng: markedUserLocation.lng,
            }}
            icon={{
              url: userPin,
              scaledSize: new window.google.maps.Size(35, 35),
            }}
            onClick={selectUserLocation}
          />
      )}
    </GoogleMap>
  );
}

Map.propTypes = {
  address: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  points: PropTypes.arrayOf(
    PropTypes.object.isRequired,
  ).isRequired,
  setPoints: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  audioFile: PropTypes.oneOfType([
    PropTypes.instanceOf(Blob),
    PropTypes.instanceOf(File),
  ]),
  setAudioFile: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  imageFile: PropTypes.oneOfType([
    PropTypes.instanceOf(Blob),
    PropTypes.instanceOf(File),
  ]),
  setImageFile: PropTypes.func.isRequired,
  formData: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    radius: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    beacon: PropTypes.string,
    isVisible: PropTypes.bool,
    isAutomaticallyPlayed: PropTypes.bool,
    audioURL: PropTypes.string,
    imageURL: PropTypes.string,
    linkURL: PropTypes.string,
    linkTitle: PropTypes.string,
  }).isRequired,
  setFormData: PropTypes.func.isRequired,
  formEditData: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    beacon: PropTypes.string,
    categoryId: PropTypes.string,
    radius: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    isVisible: PropTypes.bool,
    isAutomaticallyPlayed: PropTypes.bool,
    audioURL: PropTypes.string,
    imageURL: PropTypes.string,
    linkURL: PropTypes.string,
    linkTitle: PropTypes.string,
  }).isRequired,
  setFormEditData: PropTypes.func.isRequired,
  selectedCoords: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  setSelectedCoords: PropTypes.func.isRequired,
  selectedPoint: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    beacon: PropTypes.string,
    categoryId: PropTypes.string,
    radius: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    isVisible: PropTypes.bool,
    isAutomaticallyPlayed: PropTypes.bool,
    audioURL: PropTypes.string,
    imageURL: PropTypes.string,
    linkURL: PropTypes.string,
    linkTitle: PropTypes.string,
    latitude: PropTypes.string,
    longitude: PropTypes.string,
  }),
  setSelectedPoint: PropTypes.func.isRequired,
};
