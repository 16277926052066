import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

// ** MUI Components
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import HomeIcon from '@mui/icons-material/Home';
import RoomIcon from '@mui/icons-material/Room';
import BluetoothSearchingIcon from '@mui/icons-material/BluetoothSearching';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export default function LeftDrawer({
  isDrawerOpen,
  setIsDrawerOpen,
}) {
  const navigate = useNavigate();

  const [items, setItems] = useState([
    {
      id: 1,
      text: 'Locais',
      icon: <RoomIcon />,
      route: '/',
    },
    {
      id: 2,
      text: 'Beacons',
      icon: <BluetoothSearchingIcon />,
      route: '/beacons',
    },
  ]);

  useEffect(() => {
    let user = localStorage.getItem('user');
    user = JSON.parse(user);
    if (user.role === 'administrator') {
      setItems([
        ...items,
        {
          id: 3,
          text: 'Usuários',
          icon: <AccountCircleIcon />,
          route: '/users',
        },
      ]);
    }
  }, []);

  return (
    <Drawer
      sx={{
        width: '240px', // Set the desired width for the drawer
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: '240px', // Set the desired width for the drawer
        },
        '@media (max-width: 768px)': {
          display: 'none',
        },
      }}
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Divider />
      <List>
        {items.map((item) => (
          <Box component="span" key={item.id}>
            <ListItem disablePadding>
              <ListItemButton onClick={(e) => {
                e.preventDefault();
                navigate(item.route);
              }}
              >
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          </Box>
        ))}
      </List>
      <Divider />
    </Drawer>
  );
}

LeftDrawer.propTypes = {
  isDrawerOpen: PropTypes.bool.isRequired,
  setIsDrawerOpen: PropTypes.func.isRequired,
};
