import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import SignIn from './pages/SignIn';
// import Reset from './pages/auth/Reset';
import Home from './pages/Home';
import Beacons from './pages/Beacons';
import BeaconNew from './pages/BeaconNew';
import BeaconEdit from './pages/BeaconEdit';
import Users from './pages/Users';
import UserNew from './pages/UserNew';
import UserEdit from './pages/UserEdit';
import ExpositionDetails from './pages/ExpositionDetails';
import ExpositionNew from './pages/ExpositionNew';
import ExpositionEdit from './pages/ExpositionEdit';
import CategoryNew from './pages/CategoryNew';
import CategoryEdit from './pages/CategoryEdit';

export default function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<SignIn />} />
        {/* <Route path="/reset" element={<Reset />} /> */}
        <Route path="/home" element={<Home />} />

        <Route path="/beacons" element={<Beacons />} />
        <Route path="/beacons/new" element={<BeaconNew />} />
        <Route path="/beacons/edit" element={<BeaconEdit />} />

        <Route path="/users" element={<Users />} />
        <Route path="/users/new" element={<UserNew />} />
        <Route path="/users/edit" element={<UserEdit />} />

        <Route path="/expositions/details" element={<ExpositionDetails />} />
        <Route path="/expositions/new" element={<ExpositionNew />} />
        <Route path="/expositions/edit" element={<ExpositionEdit />} />

        <Route path="/categories/new" element={<CategoryNew />} />
        <Route path="/categories/edit" element={<CategoryEdit />} />
      </Routes>
    </Router>
  );
}
