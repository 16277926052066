import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MdEditNote,
  MdDelete,
  MdLocationPin,
} from 'react-icons/md';
import { FiCopy } from 'react-icons/fi';
import moment from 'moment';
import 'moment/locale/pt-br';

import PropTypes from 'prop-types';

import Snackbar from '@mui/material/Snackbar';

import picture from '../../../assets/logo.png';
import api from '../../../services/api';
import './styles.css';

export default function ExpositionCard({ exposition }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      handleClick();
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  async function handleDeleteExposition() {
    try {
      const confirmation = window.confirm(
        'Tem certeza que deseja excluir esta exposição?',
      );

      if (!confirmation) return;

      await api.delete(`/expositions/${exposition.id}`);
      alert('Exposição excluida com sucesso');
      navigate('/home');
    } catch (err) {
      console.error(err);
      alert(err.response.data.message);
    }
  }

  return (
    <div className="exposition-card">
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="A URL foi copiada para a sua área de transferência."
      />

      <div className="exposition-header">
        <h1>Exposição</h1>
        <span className="exposition-buttons">
          <button
            type="button"
            onClick={() => navigate('/expositions/edit', { state: { exposition } })}
          >
            <MdEditNote size={25} color="#757575" />
          </button>
          <button type="button" onClick={handleDeleteExposition}>
            <MdDelete size={25} color="#757575" />
          </button>
        </span>
      </div>
      <img src={picture} alt="logo" />
      <h2 className="exposition-subtitle">{exposition.name}</h2>
      {exposition.url && (
        <span className="url-copy">
          <p>{exposition.url}</p>
          <FiCopy
            onClick={() => copyToClipboard(exposition.url)}
            size={18}
            color="#757575"
          />
        </span>
      )}
      {exposition.city && exposition.state && (
        <span className="location-info">
          <MdLocationPin size={22} color="#757575" />
          <p>
            {`${exposition.city}, ${exposition.state}`}
          </p>
        </span>
      )}
      <footer className="footer-card">
        <h3>{`CRIADO EM ${moment(exposition.createdAt).format('MMMM YYYY')}`}</h3>
      </footer>
    </div>
  );
}

ExpositionCard.propTypes = {
  exposition: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
};
