/* eslint-disable no-unused-vars */
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import logo from '../../assets/whiteLogo.png';

export default function ResponsiveAppBar(props) {
  const navigate = useNavigate();

  const logout = (e) => {
    e.preventDefault();

    localStorage.clear();

    navigate('/');
  };

  return (
    <AppBar
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      position="fixed"
      color="secondary"
    >

      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Typography
          variant="h6"
          noWrap
          component={Link}
          to="/"
          sx={{
            mr: 2,
            fontWeight: 600,
            color: 'inherit',
            textDecoration: 'none',
          }}
        >
          <img src={logo} alt="logo" style={{ height: '50px' }} />
        </Typography>

        <Box sx={{ flexGrow: 0 }}>
          <Button
            color="inherit"
            onClick={logout}
          >
            Logout
          </Button>
        </Box>
      </Toolbar>
    </AppBar>
  );
}
