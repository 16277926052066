import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Skeleton,
} from '@mui/material';

import ResponsiveAppBar from '../../components/layout/NavBar';
import LeftDrawer from '../../components/layout/LeftDrawer';

import api from '../../services/api';

import './styles.css';

export default function Home() {
  const navigate = useNavigate();

  const [expositions, setExpositions] = useState([]);

  const [isLoadingExpositions, setIsLoadingExpositions] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  function checkAuth() {
    const isLogged = localStorage.getItem('token');
    if (!isLogged) navigate('/');
    setIsAuthenticated(true);
  }

  useEffect(() => {
    checkAuth();
  }, []);

  const loadExpositions = async () => {
    if (!isAuthenticated) return;
    try {
      const response = await api.get('/expositions');
      setExpositions(response.data);
    } catch (err) {
      console.error(err);
      alert('Falha ao carregar exposições, tente recarregar a página.');
    } finally {
      setIsLoadingExpositions(false);
    }
  };

  useEffect(() => {
    loadExpositions();
  }, [isAuthenticated]);

  function getTotalPoints(exposition) {
    let totalPoints = 0;

    exposition.categories.forEach((category) => {
      totalPoints += category.points.length;
    });

    return totalPoints;
  }

  return (
    <Box component="div" className="home-container">
      <ResponsiveAppBar />
      <LeftDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
      <Box component="span" className="home-headers">
        {isLoadingExpositions ? (
          <Skeleton variant="rectangular" width={250} height={50} />
        ) : (
          <>
            <Typography component="h1">Locais cadastrados</Typography>
            <Button
              size="medium"
              type="button"
              variant="outlined"
              color="success"
              onClick={() => navigate('/expositions/new')}
            >
              Novo local
            </Button>
          </>
        )}
      </Box>
      <Box component="main" className="home-content">
        <Box>
          {isLoadingExpositions ? (
            <Box>
              {[1, 2, 3, 4, 5, 6].map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box key={index} sx={{ mt: 2 }}>
                  <Skeleton variant="rectangular" width="100%" height={100} />
                </Box>
              ))}
            </Box>
          ) : (
            <Box>
              {expositions.map((exposition) => (
                <Box component="span" className="home-item" key={exposition.id}>
                  <Box component="span" className="home-info">
                    <Box component="span" className="home-row">
                      <Typography variant="h6" className="home-title">
                        {exposition.name}
                      </Typography>
                    </Box>
                    <Box component="span" className="home-row">
                      <Typography variant="h6" className="home-title">
                        Endereço:
                      </Typography>
                      <Typography variant="h6" className="home-body">
                        {`${exposition.address} - ${exposition.city}`}
                      </Typography>
                    </Box>
                    <Box component="span" className="home-row">
                      <Typography variant="h6" className="home-title">
                        Qtd. de pontos:
                      </Typography>
                      <Typography variant="h6" className="home-body">
                        {getTotalPoints(exposition)}
                      </Typography>
                    </Box>
                  </Box>
                  <Box component="span" className="home-actions">
                    <Button
                      sx={{ width: '150px' }}
                      size="medium"
                      type="button"
                      variant="outlined"
                      color="secondary"
                      onClick={() => navigate('/expositions/details', {
                        state: {
                          expositionId: exposition.id,
                        },
                      })}
                    >
                      Detalhes
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
