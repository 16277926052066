import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { FiArrowLeft } from 'react-icons/fi';

// ** MUI Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import illustration from '../../assets/gps-concept-light.svg';
import api from '../../services/api';
import './styles.css';

export default function ExpositionEdit() {
  const navigate = useNavigate();
  const location = useLocation();

  function checkAuth() {
    const isLogged = localStorage.getItem('token');
    if (!isLogged) navigate('/');
  }
  useEffect(() => {
    checkAuth();
  }, []);

  const removeNumberAndComma = (address) => {
    const number = address.match(/\d+/g);
    const newAddress = address.replace(number, '');
    const newAddress2 = newAddress.replace(',', '');
    return newAddress2;
  };

  const getNumber = (address) => {
    const number = address.match(/\d+/g);
    return number ? number[0] : '';
  };

  const [exposition, setExposition] = useState({
    id: location.state.exposition.id,
    name: location.state.exposition.name,
    phone: location.state.exposition.phone,
    address: removeNumberAndComma(location.state.exposition.address),
    number: getNumber(location.state.exposition.address),
    neighborhood: location.state.exposition.neighborhood,
    city: location.state.exposition.city,
    state: location.state.exposition.state,
    cep: location.state.exposition.cep,
    logo: location.state.exposition.logo,
    url: location.state.exposition.url,
    colorPrimary: location.state.exposition.colorPrimary,
    colorSecondary: location.state.exposition.colorSecondary,
  });

  const handleInputChangeData = (e) => {
    const { name, value } = e.target;
    setExposition(({
      ...exposition,
      [name]: value,
    }));
  };

  async function handleUpdate(e) {
    e.preventDefault();

    const data = {
      name: exposition.name,
      phone: exposition.phone,
      address: exposition.number ? `${exposition.address}, ${exposition.number}` : exposition.address,
      neighborhood: exposition.neighborhood,
      city: exposition.city,
      state: exposition.state,
      cep: exposition.cep,
      logo: exposition.logo,
      url: exposition.url,
    };

    try {
      // verifica caso o campo UF tenha sido preenchido, se é uma UF válida
      if (exposition.state !== '') {
        const apiURL = (`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${exposition.state}`);
        const response = await axios.get(apiURL);
        if (!response.data.sigla) {
          alert('UF inválida.');
          return;
        }
      }

      await api.patch(`/expositions/${exposition.id}`, data);

      alert('Exposição alterada com sucesso.');

      navigate('/expositions/details', {
        state: {
          expositionId: exposition.id,
        },
      });
    } catch (err) {
      console.error(err);
      alert(err.response.data.message);
    }
  }

  const getAddress = async () => {
    try {
      if (!exposition.cep) return;

      const apiURL = (`https://viacep.com.br/ws/${exposition.cep}/json/`);
      const response = await axios.get(apiURL);
      const { data } = response;
      setExposition({
        ...exposition,
        address: data.logradouro,
        neighborhood: data.bairro,
        city: data.localidade,
        state: data.uf,
      });
    } catch (error) {
      console.error('Erro ao buscar endereço:', error);
    }
  };

  return (
    <div className="edit-exibition-container">
      <main className="content">
        <section>
          <img src={illustration} alt="Maps Indoor" />
          <div className="div-text">
            <h1>Editar exposição</h1>
            <p>Preencha os dados referente a exposição.</p>
          </div>
          <Link className="back-link" to={-1}>
            <FiArrowLeft size={20} color="#414141" />
            Voltar para exposição
          </Link>
        </section>
        <form onSubmit={handleUpdate}>
          <FormControl
            fullWidth
            sx={{
              my: 1,
            }}
          >
            <TextField
              label="Título"
              value={exposition.name}
              type="text"
              name="name"
              placeholder="Digite o título"
              onChange={(e) => handleInputChangeData(e)}
              required
              inputProps={{
                maxLength: 100,
              }}
            />
          </FormControl>

          <FormControl
            fullWidth
            sx={{
              my: 1,
            }}
          >
            <TextField
              label="Site"
              value={exposition.url}
              type="url"
              name="url"
              placeholder="Digite a URL do site"
              onChange={(e) => handleInputChangeData(e)}
              inputProps={{
                maxLength: 100,
              }}
            />
          </FormControl>

          <FormControl
            fullWidth
            sx={{
              my: 1,
            }}
          >
            <TextField
              label="Celular"
              value={exposition.phone}
              type="tel"
              name="phone"
              placeholder="Número de telefone"
              onChange={(e) => handleInputChangeData(e)}
              inputProps={{
                maxLength: 15,
              }}
            />
          </FormControl>
          <FormControl
            fullWidth
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              my: 1,
            }}
          >
            <TextField
              sx={{ flex: 7 }}
              label="Endereço"
              value={exposition.address}
              type="text"
              name="address"
              placeholder="Rua F"
              onChange={(e) => handleInputChangeData(e)}
              inputProps={{
                maxLength: 50,
              }}
            />
            <TextField
              sx={{ flex: 3 }}
              label="Número"
              value={exposition.number}
              type="text"
              name="number"
              placeholder="123"
              onChange={(e) => handleInputChangeData(e)}
              inputProps={{
                maxLength: 5,
              }}
            />
          </FormControl>

          <FormControl
            fullWidth
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              my: 2,
            }}
          >
            <TextField
              sx={{ flex: 7 }}
              label="Bairro"
              value={exposition.neighborhood}
              type="text"
              name="neighborhood"
              onChange={(e) => handleInputChangeData(e)}
              placeholder="Jardim Rosa"
              inputProps={{
                maxLength: 50,
              }}
            />
            <TextField
              sx={{ flex: 3 }}
              label="CEP"
              value={exposition.cep}
              type="text"
              name="cep"
              onChange={(e) => handleInputChangeData(e)}
              onBlur={getAddress}
              placeholder="08123-123"
              inputProps={{
                maxLength: 9,
                minLength: 9,
              }}
            />
          </FormControl>
          <FormControl
            fullWidth
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              my: 2,
            }}
          >
            <TextField
              sx={{ flex: 7 }}
              label="Cidade"
              value={exposition.city}
              type="text"
              name="city"
              placeholder="São Paulo"
              onChange={(e) => handleInputChangeData(e)}
              inputProps={{
                maxLength: 50,
              }}
            />
            <TextField
              sx={{ flex: 3 }}
              label="UF"
              value={exposition.state}
              type="text"
              name="state"
              placeholder="SP"
              onChange={(e) => handleInputChangeData(e)}
              onInput={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
              inputProps={{
                maxLength: 2,
              }}
            />
          </FormControl>
          <Button
            sx={{
              mt: 2,
              width: '100%',
              height: 60,
            }}
            type="submit"
            size="large"
            variant="contained"
          >
            Alterar
          </Button>
        </form>
      </main>
    </div>
  );
}
