import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import Map from '../../components/maps/GoogleMap';

import ResponsiveAppBar from '../../components/layout/NavBar';
import ExpositionCard from '../../components/exposition/ExpositionCard';
import CategoriesCard from '../../components/exposition/CategoriesCard';
import PointsCard from '../../components/exposition/PointsCard';
import BeaconsCard from '../../components/exposition/BeaconsCard';

import api from '../../services/api';

import './styles.css';

export default function ExpositionDetails() {
  const location = useLocation();
  const navigate = useNavigate();

  function checkAuth() {
    const isLogged = localStorage.getItem('token');
    if (!isLogged) navigate('/');
  }

  useEffect(() => {
    checkAuth();
  }, []);

  const [isLoaded, setIsLoaded] = useState(false);

  const { expositionId } = location.state;
  const [exposition, setExposition] = useState({});
  const [categories, setCategories] = useState({});
  const [points, setPoints] = useState({});

  const loadData = async () => {
    try {
      const response = await api.get(`/expositions/${expositionId}`);
      setExposition(response.data);
      setCategories(response.data.categories);
      setPoints(
        response.data.categories.reduce((acc, category) => [
          ...acc,
          ...category.points.map((point) => ({
            ...point,
            category: category.name,
          })),
        ], []),
      );

      setIsLoaded(true);
    } catch (err) {
      console.error(err);
      // eslint-disable-next-line no-use-before-define
      alert('Falha ao carregar exposição, tente recarregar a página.');
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const address = `${exposition.address} - ${exposition.neighborhood}, ${exposition.city}${exposition.state}${exposition.cep}`;

  const [audioFile, setAudioFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const [formData, setFormData] = useState({
    type: 'gps',
    name: '',
    category: '',
    description: '',
    radius: 10,
    beacon: '',
    isVisible: true,
    isAutomaticallyPlayed: true,
    audioUrl: '',
    imageUrl: '',
    linkURL: '',
    linkTitle: '',
  });

  const [formEditData, setFormEditData] = useState({
    id: '',
    type: 'gps',
    name: '',
    categoryId: '',
    category: '',
    description: '',
    radius: 10,
    beacon: '',
    isVisible: true,
    isAutomaticallyPlayed: true,
    audioURL: '',
    imageURL: '',
    linkURL: '',
    linkTitle: '',
  });

  const [selectedCoords, setSelectedCoords] = useState({ lat: null, lng: null });
  const [selectedPoint, setSelectedPoint] = useState(null);

  const mapRef = useRef(null);

  const scrollToMapComponent = () => {
    if (mapRef.current) {
      mapRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="exposition-details-container">
      <ResponsiveAppBar />
      {isLoaded
        && (
          <main>
            <ExpositionCard exposition={exposition} />
            <CategoriesCard
              expositionId={exposition.id}
              categories={categories}
            />
            <div
              className="map-card"
              ref={mapRef}
            >
              <span className="map-title">
                <h1>Local</h1>
              </span>
              <Map
                address={address}
                categories={categories}
                points={points}
                setPoints={setPoints}
                audioFile={audioFile}
                setAudioFile={setAudioFile}
                imageFile={imageFile}
                setImageFile={setImageFile}
                formData={formData}
                setFormData={setFormData}
                formEditData={formEditData}
                setFormEditData={setFormEditData}
                selectedCoords={selectedCoords}
                setSelectedCoords={setSelectedCoords}
                selectedPoint={selectedPoint}
                setSelectedPoint={setSelectedPoint}
              />
            </div>
            <PointsCard
              scrollToMapComponent={scrollToMapComponent}
              points={points}
              setPoints={setPoints}
              setAudioFile={setAudioFile}
              setImageFile={setImageFile}
              setFormData={setFormData}
              setFormEditData={setFormEditData}
              selectedCoords={selectedCoords}
              setSelectedCoords={setSelectedCoords}
              setSelectedPoint={setSelectedPoint}
            />
            <BeaconsCard
              scrollToMapComponent={scrollToMapComponent}
              points={points}
              setPoints={setPoints}
              setAudioFile={setAudioFile}
              setImageFile={setImageFile}
              setFormData={setFormData}
              setFormEditData={setFormEditData}
              selectedCoords={selectedCoords}
              setSelectedCoords={setSelectedCoords}
              setSelectedPoint={setSelectedPoint}
            />
          </main>
        )}
    </div>
  );
}
