import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Skeleton,
} from '@mui/material';

import ResponsiveAppBar from '../../components/layout/NavBar';
import LeftDrawer from '../../components/layout/LeftDrawer';

import api from '../../services/api';

import './styles.css';

export default function Beacons() {
  const navigate = useNavigate();
  const [beacons, setBeacons] = useState([]);

  const [isLoadingBeacons, setIsLoadingBeacons] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  function checkAuth() {
    const isLogged = localStorage.getItem('token');
    if (!isLogged) navigate('/');
    setIsAuthenticated(true);
  }

  useEffect(() => {
    checkAuth();
  }, []);

  async function loadBeacons() {
    if (!isAuthenticated) return;

    try {
      const response = await api.get('/beacons');
      const sortedBeacons = response.data.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });

      setBeacons(sortedBeacons);
    } catch (err) {
      console.error(err);
      alert('Falha ao carregar beacons, tente recarregar a página.');
    } finally {
      setIsLoadingBeacons(false);
    }
  }

  useEffect(() => {
    loadBeacons();
  }, [isAuthenticated]);

  const handleDeleteBeacon = async (id) => {
    const confirmed = window.confirm('Tem certeza que deseja apagar esse beacon?');

    if (!confirmed) {
      return;
    }

    try {
      await api.delete(`/beacons/${id}`);
      alert('Beacon apagado com sucesso.');
      loadBeacons();
    } catch (err) {
      console.error(err);
      alert('Falha ao apagar beacon, tente novamente.');
    }
  };

  return (
    <Box component="div" className="beacons-container">
      <ResponsiveAppBar />
      <LeftDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
      <Box component="span" className="beacons-headers">
        {isLoadingBeacons ? (
          <Skeleton variant="rectangular" width={250} height={50} />
        ) : (
          <>
            <Typography component="h1">Beacons cadastrados</Typography>
            <Button
              size="medium"
              type="button"
              variant="outlined"
              color="success"
              onClick={() => navigate('/beacons/new')}
            >
              Novo beacon
            </Button>
          </>
        )}
      </Box>
      <Box component="main" className="beacons-content">
        <Box>
          {isLoadingBeacons ? (
            <Box>
              {[1, 2, 3, 4, 5, 6].map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box key={index} sx={{ mt: 2 }}>
                  <Skeleton variant="rectangular" width="100%" height={100} />
                </Box>
              ))}
            </Box>
          ) : (
            <Box>
              {beacons.map((beacon) => (
                <Box component="span" className="beacons-item" key={beacon.id}>
                  <Box component="span" className="beacons-info">
                    <Box component="span" className="beacons-row">
                      <Typography variant="h6" className="beacons-title">
                        {`${beacon.brand} - ${beacon.name}`}
                      </Typography>
                    </Box>
                    <Box component="span" className="beacons-row">
                      <Typography variant="h6" className="beacons-title">
                        Identifier:
                      </Typography>
                      <Typography variant="h6" className="beacons-body">
                        {beacon.id}
                      </Typography>
                    </Box>
                    <Box component="span" className="beacons-row">
                      <Typography variant="h6" className="beacons-title">
                        {`${beacon.type}:`}
                      </Typography>
                      {beacon.type !== 'eddystone' ? (
                        <Typography variant="h6" className="beacons-body">
                          {`${beacon.deviceId} - ${beacon.major}:${beacon.minor}`}
                        </Typography>
                      ) : (
                        <Typography variant="h6" className="beacons-body">
                          {`${beacon.namespace} : ${beacon.instanceId}`}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box component="span" className="beacons-actions">
                    <Button
                      sx={{ width: '150px' }}
                      size="medium"
                      type="button"
                      variant="outlined"
                      color="secondary"
                      onClick={() => navigate('/beacons/edit', {
                        state: {
                          beacon,
                        },
                      })}
                    >
                      Editar
                    </Button>
                    <Button
                      sx={{ width: '150px' }}
                      size="medium"
                      type="button"
                      variant="outlined"
                      color="error"
                      onClick={() => handleDeleteBeacon(beacon.id)}
                    >
                      Excluir
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>

      </Box>
    </Box>
  );
}
