/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

// ** MUI Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import illustration from '../../assets/archive-concept-light.svg';
import api from '../../services/api';
import './styles.css';

export default function CategoryEdit() {
  const navigate = useNavigate();
  const location = useLocation();

  const { expositionId } = location.state;

  const [id, setId] = useState(location.state.category.id);
  const [name, setName] = useState(location.state.category.name);

  async function handleUpdate(e) {
    e.preventDefault();
    const data = {
      name,
    };
    try {
      await api.patch(`/categories/${id}`, data);
      alert('Categoria alterada com sucesso');
      navigate('/expositions/details', {
        state: {
          expositionId,
        },
      });
    } catch (err) {
      console.error(err);
      alert(err.response.data.message);
    }
  }

  async function handleDelete() {
    try {
      if (location.state.category.points.length > 0) {
        alert(
          'Não é possível excluir esta categoria, pois ela possui pontos associados',
        );
        return;
      }

      const confirmation = window.confirm(
        'Tem certeza que deseja excluir esta categoria?',
      );

      if (!confirmation) return;

      await api.delete(`/categories/${id}`);
      alert('Categoria excluida com sucesso');
      navigate('/expositions/details', {
        state: {
          expositionId,
        },
      });
    } catch (err) {
      console.error(err);
      alert(err.response.data.message);
    }
  }

  const handleGoBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <div className="edit-category-container">
      <main className="content">
        <section>
          <img src={illustration} alt="Maps Indoor" />
        </section>
        <form onSubmit={handleUpdate}>
          <h1>Editar categoria</h1>
          <p>Preencha os dados referente a categoria.</p>
          <FormControl
            fullWidth
            sx={{
              my: 1,
            }}
          >
            <TextField
              label="Nome"
              value={name}
              type="text"
              placeholder="Digite o nome"
              onChange={(e) => setName(e.target.value)}
              required
            />
          </FormControl>
          <Button
            sx={{
              mt: 1,
              width: '100%',
              height: 60,
            }}
            type="submit"
            size="large"
            variant="contained"
          >
            Salvar
          </Button>
          <Button
            sx={{
              mt: 1,
              width: '100%',
              height: 60,
            }}
            type="button"
            size="large"
            variant="outlined"
            color="error"
            onClick={() => handleDelete()}
          >
            Excluir
          </Button>
          <div className="back-button">
            <Link className="back-link" to="#" onClick={handleGoBack}>
              <FiArrowLeft size={20} color="#414141" />
              Voltar para exposição
            </Link>
          </div>
        </form>
      </main>
    </div>
  );
}
