/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Input,
  InputAdornment,
} from '@mui/material';

import LinkIcon from '@mui/icons-material/Link';

import PropTypes from 'prop-types';

import api from '../../services/api';

export default function PointRegisterWindow({
  // selectedCoords,
  formData,
  categories,
  audioFile,
  setAudioFile,
  imageFile,
  setImageFile,
  handleRegister,
  handleInputChangeData,
}) {
  const [beacons, setBeacons] = useState([]);

  useEffect(() => {
    async function loadBeacons() {
      try {
        const response = await api.get('/beacons');
        const sortedBeacons = response.data.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });

        setBeacons(sortedBeacons);
      } catch (err) {
        console.error(err);
      }
    }
    loadBeacons();
  }, []);

  const isImageFileTypeAllowed = (file) => {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/gif'];
    return allowedTypes.includes(file.type);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 50 * 1024 * 1024; // 50MB

    if (!file) {
      setImageFile(null);
      document.getElementById('image-input').value = '';
      return;
    }

    if (!file.type.startsWith('image/')) {
      setImageFile(null);
      document.getElementById('image-input').value = '';
      alert('Por favor, selecione um arquivo de imagem');
      return;
    }

    // Verificar se o tipo de arquivo é permitido
    if (!isImageFileTypeAllowed(file)) {
      setImageFile(null);
      document.getElementById('image-input').value = '';
      alert('Por favor, selecione um arquivo de imagem válido (jpg|jpeg|png|webp|gif).');
      return;
    }

    if (file.size > maxSizeInBytes) {
      setImageFile(null);
      document.getElementById('image-input').value = '';
      alert('Por favor, selecione um arquivo de imagem com tamanho máximo de 50');
      return;
    }

    setImageFile(file);
  };

  const handleImageRemoval = () => {
    setImageFile(null);
    document.getElementById('image-input').value = '';
  };

  const isAudioFileTypeAllowed = (file) => {
    const allowedTypes = ['audio/mp3', 'audio/aac', 'audio/ogg', 'audio/wav', 'audio/aiff', 'audio/wma', 'audio/mpeg'];
    return allowedTypes.includes(file.type);
  };

  const handleAudioChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 50 * 1024 * 1024; // 50mb

    if (!file) {
      setAudioFile(null);
      document.getElementById('audio-input').value = '';
      return;
    }

    if (!file.type.startsWith('audio/')) {
      setAudioFile(null);
      document.getElementById('audio-input').value = '';
      alert('Por favor, selecione um arquivo de áudio');
      return;
    }

    // Verificar se o tipo de arquivo é permitido
    if (!isAudioFileTypeAllowed(file)) {
      setAudioFile(null);
      document.getElementById('audio-input').value = '';
      alert('Por favor, selecione um arquivo de audio válido (mp3|aac|ogg|wav|aiff|wma|mepg).');
      return;
    }

    if (file.size > maxSizeInBytes) {
      setAudioFile(null);
      document.getElementById('audio-input').value = '';
      alert('Por favor, selecione um arquivo de áudio com tamanho máximo de 50MB');
      return;
    }

    setAudioFile(file);
  };

  const handleAudioRemoval = () => {
    setAudioFile(null);
    document.getElementById('audio-input').value = '';
  };

  return (
    <Box sx={{ width: 450, p: 3 }}>
      <form onSubmit={handleRegister}>
        <FormControl fullWidth sx={{ my: 1 }}>
          <InputLabel id="type-label">Tipo</InputLabel>
          <Select
            name="type"
            labelid="type-label"
            id="type-select"
            label="Tipo"
            value={formData.type}
            onChange={handleInputChangeData}
          >
            <MenuItem value="gps">GPS</MenuItem>
            <MenuItem value="beacon">Beacon</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ my: 1 }}>
          <TextField
            name="name"
            label="Nome"
            type="text"
            value={formData.name}
            placeholder="Dígite um nome para o ponto"
            onChange={handleInputChangeData}
            required
            inputProps={{
              maxLength: 100,
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 1 }}>
          <InputLabel id="category-label">Categoria</InputLabel>
          <Select
            name="category"
            labelid="category-label"
            id="category-select"
            label="Categoria"
            value={formData.category}
            onChange={handleInputChangeData}
          >
            <MenuItem
              sx={{ color: '#B5B5B5' }}
              value={undefined}
            >
              Selecionar...
            </MenuItem>
            {categories.map((category) => (
              <MenuItem
                key={category.id}
                value={category.id}
              >
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ my: 1 }}>
          <TextField
            name="linkURL"
            label="Link URL"
            type="url"
            value={formData.linkURL}
            placeholder="Dígite um link para o ponto"
            helperText="Ex.: https://www.google.com.br"
            onChange={handleInputChangeData}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                >
                  <LinkIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 1 }}>
          <TextField
            name="linkTitle"
            label="Título para o link"
            type="text"
            value={formData.linkTitle}
            placeholder="Dígite um título para o link"
            helperText="Ex.: Google"
            onChange={handleInputChangeData}
            inputProps={{
              maxLength: 100,
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 1 }}>
          <TextField
            name="description"
            label="Descrição"
            type="text"
            multiline
            rows={5}
            value={formData.description}
            placeholder="Descreva do que se trata o ponto marcado"
            onChange={handleInputChangeData}
            inputProps={{
              maxLength: 500,
            }}
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1,
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
          }}
        >
          <Input
            label="Imagem"
            id="image-input"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
          <Button
            sx={{ mt: 1 }}
            size="large"
            variant="outlined"
            color="error"
            onClick={handleImageRemoval}
          >
            Remover
          </Button>
        </FormControl>
        {imageFile && (
          <div style={{ marginTop: '20px' }}>
            <Typography variant="body1">Imagem selecionada:</Typography>
            <img
              src={URL.createObjectURL(imageFile)}
              alt="Imagem selecionada"
              style={{ maxWidth: '100%', marginTop: '10px' }}
            />
          </div>
        )}
        {formData.type === 'gps' ? (
          <FormControl fullWidth sx={{ my: 1 }}>
            <TextField
              fullWidth
              label="Raio"
              variant="outlined"
              value={formData.radius}
              onChange={handleInputChangeData}
              name="radius"
              type="number"
              inputProps={{ min: 0, max: 200 }}
              required={formData.type === 'gps'}
              helperText="Raio em metros"
            />
          </FormControl>
        ) : (
          <FormControl fullWidth sx={{ my: 1 }}>
            <InputLabel id="beacon-label">Beacon</InputLabel>
            <Select
              name="beacon"
              labelid="beacon-label"
              id="beacon-select"
              label="Beacon"
              value={formData.beacon}
              onChange={handleInputChangeData}
            >
              <MenuItem
                sx={{ color: '#B5B5B5' }}
                value={undefined}
              >
                Selecionar...
              </MenuItem>
              {beacons.map((beacon) => (
                <MenuItem
                  key={beacon.id}
                  value={beacon.id}
                >
                  {beacon.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl fullWidth sx={{ my: 1 }}>
          <InputLabel id="isVisible-label">Visibilidade</InputLabel>
          <Select
            name="isVisible"
            labelid="isVisible-label"
            id="isVisible-select"
            label="Visibilidade"
            value={formData.isVisible}
            onChange={handleInputChangeData}
          >
            <MenuItem value={true}>Visível</MenuItem>
            <MenuItem value={false}>Invisível</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ my: 1 }}>
          <InputLabel id="isAutomaticallyPlayed-label">Reprodução automática</InputLabel>
          <Select
            name="isAutomaticallyPlayed"
            labelid="isAutomaticallyPlayed-label"
            id="isAutomaticallyPlayed-select"
            label="Reprodução automática"
            value={formData.isAutomaticallyPlayed}
            onChange={handleInputChangeData}
          >
            <MenuItem value={true}>Sim</MenuItem>
            <MenuItem value={false}>Não</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1,
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
          }}
        >
          <Input
            label="Áudio"
            id="audio-input"
            type="file"
            accept="audio/*"
            onChange={handleAudioChange}
          />
          <Button
            sx={{ mt: 1 }}
            size="large"
            variant="outlined"
            color="error"
            onClick={handleAudioRemoval}
          >
            Remover
          </Button>
        </FormControl>
        {audioFile && (
          <div style={{ marginTop: '20px' }}>
            <Typography variant="body1">Áudio selecionado:</Typography>
            <audio controls src={URL.createObjectURL(audioFile)} style={{ width: '100%' }}>
              <track kind="captions" />
            </audio>
          </div>
        )}
        {/* <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: 2,
          my: 1,
        }}
        >
          <TextField
            sx={{ width: '100%' }}
            value={Number(selectedCoords.lat)}
            disabled
          />
          <TextField
            sx={{ width: '100%' }}
            value={Number(selectedCoords.lng)}
            disabled
          />
        </Box> */}
        <Button
          sx={{ mt: 1, width: '100%' }}
          size="large"
          variant="contained"
          color="success"
          type="submit"
        >
          Registrar
        </Button>
      </form>
    </Box>
  );
}

PointRegisterWindow.propTypes = {
  selectedCoords: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }).isRequired,
  formData: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    radius: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    beacon: PropTypes.string,
    isVisible: PropTypes.bool,
    isAutomaticallyPlayed: PropTypes.bool,
    linkURL: PropTypes.string,
    linkTitle: PropTypes.string,
  }).isRequired,
  audioFile: PropTypes.instanceOf(File),
  setAudioFile: PropTypes.func.isRequired,
  imageFile: PropTypes.instanceOf(File),
  setImageFile: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  handleRegister: PropTypes.func.isRequired,
  handleInputChangeData: PropTypes.func.isRequired,
};
