import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// ** MUI Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import illustration from '../../assets/gps-concept-light.svg';
import api from '../../services/api';
import './styles.css';

export default function SignIn() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const isLogged = localStorage.getItem('token');
    if (isLogged) navigate('/home');
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    const data = {
      email,
      password,
    };

    try {
      if (!email || !password) {
        alert('Preencha todos os campos!');
        return;
      }

      const response = await api.post('/auth/login', data);

      // eslint-disable-next-line camelcase
      const { access_token } = response.data;

      // Armazena token de autenticação
      localStorage.setItem('token', access_token);
      // Armazena informações do usuário
      localStorage.setItem('user', JSON.stringify(response.data.user));

      navigate('/home');
    } catch (err) {
      console.error(err);
      if (err.response.status === 500) {
        alert(
          'Falha ao autenticar usuário, tente novamente mais tarde!',
        );
      } else {
        alert('E-mail ou senha inválidos!');
      }
    }
  };

  return (
    <div className="login-container">
      <main className="content">
        <form noValidate autoComplete="off" onSubmit={handleLogin}>
          <h1>Login</h1>
          <FormControl
            fullWidth
            sx={{
              my: 2,
            }}
          >
            <TextField
              label="E-mail"
              value={email}
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              placeholder="seuemail@gmail.com"
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              placeholder="Senha"
            />
          </FormControl>
          <Button
            sx={{
              width: '100%',
              height: 60,
            }}
            type="submit"
            size="large"
            variant="contained"
          >
            Entrar
          </Button>
        </form>
        <section>
          <img src={illustration} alt="Illustration" />
        </section>
      </main>
    </div>
  );
}
