/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import { MdEditNote } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';

import { DataGrid } from '@mui/x-data-grid';

import './styles.css';

export default function CategoriesCard({
  categories,
  expositionId,
}) {
  const navigate = useNavigate();

  const handleRowClick = (params) => {
    const { api } = params;
    const thisRow = {};
    api
      .getAllColumns()
      .filter((c) => c.field !== 'action' && !!c)
      .forEach((c) => (thisRow[c.field] = params.row[c.field]));

    const rowAllValues = {
      ...thisRow,
      id: params.id,
      updatedAt: categories.find((c) => c.id === params.id).updatedAt,
      points: categories.find((c) => c.id === params.id).points,
    };

    navigate('/categories/edit', {
      state: {
        category: rowAllValues,
        expositionId,
      },
    });
  };

  const renderCell = (params) => (
    <MdEditNote
      color="#414141"
      size={25}
      onClick={(e) => {
        e.stopPropagation();
        handleRowClick(params);
      }}
      style={{
        cursor: 'pointer',
      }}
    />
  );

  const columns = [
    {
      field: 'action',
      headerName: '#',
      sortable: false,
      headerAlign: 'center',
      disableColumnMenu: true, // disable column menu
      renderCell,
      flex: 1,
    },
    {
      field: 'name',
      headerName: 'Nome',
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'updatedAt',
      headerName: 'Última alt.',
      valueFormatter: (params) => moment(params.value).format('L'),
      headerAlign: 'center',
      flex: 1,
    },
  ];

  const dataGridStyles = {
    mt: 2,
    border: 'none',
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-row': {
      borderBottom: 'none',
    },
    '& .MuiDataGrid-rowSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-cell': {
      // borderBottom: 'none',

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
  };

  return (
    <div className="categories-card">
      <span className="categories-title">
        <h1>Categorias</h1>
        <span>
          <button
            type="button"
            onClick={() => navigate('/categories/new', {
              state: {
                expositionId,
              },
            })}
          >
            <BsPlus size={35} color="#757575" />
          </button>
        </span>
      </span>
      <DataGrid
        sx={dataGridStyles}
        autoHeight
        rows={categories}
        columns={columns}
        rowsPerPageOptions={[5]}
        pageSize={5}
        disableSelectionOnClick
      />
    </div>
  );
}
