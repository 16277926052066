/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

// ** MUI Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import illustration from '../../assets/location-concept-light.svg';
import api from '../../services/api';
import './styles.css';

export default function BeaconNew() {
  const navigate = useNavigate();

  const [beacon, setBeacon] = useState({
    name: '',
    brand: '',
    type: 'iBeacon',
    deviceId: '',
    major: 0,
    minor: 0,
    namespace: '',
    instanceId: '',
    rssi: -55,
  });

  const handleInputChangeData = (e) => {
    const { name, value } = e.target;
    setBeacon(({
      ...beacon,
      [name]: value,
    }));
  };

  async function handleRegister(e) {
    e.preventDefault();

    const data = {
      name: beacon.name,
      brand: beacon.brand,
      type: beacon.type,
      deviceId: 'iBeacon',
      major: beacon.major,
      minor: beacon.minor,
      namespace: undefined,
      instanceId: undefined,
      rssi: -55,
    };

    try {
      if (beacon.brand === '') {
        alert('A marca do beacon não pode ser vazia.');
        return;
      }
      await api.post('/beacons', data);
      alert('Beacon criado com sucesso.');
      navigate('/beacons');
    } catch (err) {
      console.error(err);
      alert(err.response.data.message);
    }
  }

  const handleGoBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  return (
    <div className="new-beacon-container">
      <main className="content">
        <section>
          <img src={illustration} alt="Maps Indoor" />
        </section>
        <form onSubmit={handleRegister}>
          <h1>Novo Beacon</h1>
          <p>Preencha os dados referente ao beacon.</p>
          <FormControl
            fullWidth
            sx={{
              my: 1,
            }}
          >
            <TextField
              label="Nome"
              type="text"
              name="name"
              value={beacon.name}
              placeholder="Informe o nome do Beacon"
              onChange={(e) => handleInputChangeData(e)}
              required
            />
          </FormControl>
          <FormControl
            fullWidth
            sx={{
              my: 1,
            }}
          >
            <InputLabel id="brand-label">Marca</InputLabel>
            <Select
              id="brand"
              labelId="brand-label"
              label="Marca"
              name="brand"
              value={beacon.brand}
              onChange={(e) => handleInputChangeData(e)}
            >
              <MenuItem value="estimote">Estimote</MenuItem>
              <MenuItem value="kontakt">Kontaktio</MenuItem>
              <MenuItem value="other">Outro</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            fullWidth
            sx={{
              my: 1,
            }}
          >
            <TextField
              label="UUID"
              type="text"
              name="deviceId"
              value={beacon.deviceId}
              placeholder="Informe o ID do Beacon"
              helperText={(
                <span style={{ fontSize: '12px' }}>
                  Ex.: 5a921f29-de3c-e54c-60f7-dde6dd8d0fe8
                </span>
              )}
              onChange={(e) => handleInputChangeData(e)}
              required
            />
          </FormControl>
          <FormControl
            fullWidth
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 1,
              my: 1,
            }}
          >
            <TextField
              label="Major"
              type="number"
              name="major"
              value={beacon.major}
              placeholder="Major"
              helperText={(
                <span style={{ fontSize: '12px' }}>
                  Ex.: 2000
                </span>
              )}
              onChange={(e) => handleInputChangeData(e)}
              inputProps={{
                min: 0,
              }}
            />
            <TextField
              label="Minor"
              type="number"
              name="minor"
              placeholder="Minor"
              helperText={(
                <span style={{ fontSize: '12px' }}>
                  Ex.: 2000
                </span>
              )}
              value={beacon.minor}
              onChange={(e) => handleInputChangeData(e)}
              inputProps={{
                min: 0,
              }}
            />
          </FormControl>
          <Button
            sx={{
              mt: 1,
              width: '100%',
              height: 60,
            }}
            type="submit"
            size="large"
            variant="contained"
          >
            Cadastrar
          </Button>
          <div className="back-button">
            <Link className="back-link" to="#" onClick={handleGoBack}>
              <FiArrowLeft size={20} color="#414141" />
              Voltar para home
            </Link>
          </div>
        </form>
      </main>
    </div>
  );
}
