import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#414141',
    },
    secondary: {
      main: '#5c257e',
    },
    background: {
      default: '#f0f0f5',
    },
  },
});

export default theme;
