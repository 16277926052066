import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Skeleton,
} from '@mui/material';

import ResponsiveAppBar from '../../components/layout/NavBar';
import LeftDrawer from '../../components/layout/LeftDrawer';

import api from '../../services/api';

import './styles.css';

export default function Users() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  function checkAuth() {
    const isLogged = localStorage.getItem('token');
    if (!isLogged) navigate('/');
    setIsAuthenticated(true);
  }

  useEffect(() => {
    checkAuth();
  }, []);

  async function loadUsers() {
    if (!isAuthenticated) return;

    try {
      const response = await api.get('/users');

      setUsers(response.data);
    } catch (err) {
      console.error(err);
      alert('Falha ao carregar usuários, tente recarregar a página.');
    } finally {
      setIsLoadingUsers(false);
    }
  }

  useEffect(() => {
    loadUsers();
  }, [isAuthenticated]);

  const handleDeleteUser = async (id) => {
    const confirmed = window.confirm('Tem certeza que deseja excluir esse usuário?');

    if (!confirmed) {
      return;
    }

    try {
      await api.delete(`/users/${id}`);
      alert('Usuário apagado com sucesso.');
      loadUsers();
    } catch (err) {
      console.error(err);
      alert('Falha ao apagar usuário, tente novamente.');
    }
  };

  return (
    <Box component="div" className="users-container">
      <ResponsiveAppBar />
      <LeftDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
      <Box component="span" className="users-headers">
        {isLoadingUsers ? (
          <Skeleton variant="rectangular" width={250} height={50} />
        ) : (
          <>
            <Typography component="h1">Usuários cadastrados</Typography>
            <Button
              size="medium"
              type="button"
              variant="outlined"
              color="success"
              onClick={() => navigate('/users/new')}
            >
              Novo usuário
            </Button>
          </>
        )}
      </Box>
      <Box component="main" className="users-content">
        <Box>
          {isLoadingUsers ? (
            <Box>
              {[1, 2, 3, 4, 5, 6].map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box key={index} sx={{ mt: 2 }}>
                  <Skeleton variant="rectangular" width="100%" height={100} />
                </Box>
              ))}
            </Box>
          ) : (
            <Box>
              {users.map((user) => (
                <Box component="span" className="users-item" key={user.id}>
                  <Box component="span" className="users-info">
                    <Box component="span" className="users-row">
                      <Typography variant="h6" className="users-title">
                        Nome:
                      </Typography>
                      <Typography variant="h6" className="users-title">
                        {user.name}
                      </Typography>
                    </Box>
                    <Box component="span" className="users-row">
                      <Typography variant="h6" className="users-title">
                        E-mail:
                      </Typography>
                      <Typography variant="h6" className="users-body">
                        {user.email}
                      </Typography>
                    </Box>
                    <Box component="span" className="users-row">
                      <Typography variant="h6" className="users-title">
                        Permissão:
                      </Typography>
                      <Typography
                        variant="h6"
                        className="users-body"
                        sx={{
                          textTransform: 'capitalize',
                        }}
                      >
                        {user.role}
                      </Typography>
                    </Box>
                  </Box>
                  <Box component="span" className="users-actions">
                    <Button
                      sx={{ width: '150px' }}
                      size="medium"
                      type="button"
                      variant="outlined"
                      color="secondary"
                      onClick={() => navigate('/users/edit', {
                        state: {
                          user,
                        },
                      })}
                    >
                      Editar
                    </Button>
                    {user.id !== '57306640-28bf-4c79-ad28-0bf95a14e90a' && (
                      <Button
                        sx={{ width: '150px' }}
                        size="medium"
                        type="button"
                        variant="outlined"
                        color="error"
                        onClick={() => handleDeleteUser(user.id)}
                      >
                        Excluir
                      </Button>
                    )}
                  </Box>
                </Box>
              ))}
            </Box>
          )}
        </Box>

      </Box>
    </Box>
  );
}
