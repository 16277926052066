/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-boolean-value */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Input,
  InputAdornment,
} from '@mui/material';

import LinkIcon from '@mui/icons-material/Link';

import PropTypes from 'prop-types';

import api from '../../services/api';

export default function PointUpdateWindow({
  // selectedPoint,
  setSelectedPoint,
  formEditData,
  setFormEditData,
  audioFile,
  setAudioFile,
  imageFile,
  setImageFile,
  categories,
  handleUpdate,
  handleInputChangeEditData,
}) {
  const [beacons, setBeacons] = useState([]);
  const [isBeaconsLoaded, setIsBeaconsLoaded] = useState(false);

  useEffect(() => {
    async function loadBeacons() {
      try {
        const response = await api.get('/beacons');
        const sortedBeacons = response.data.sort((a, b) => {
          const nameA = a.name.toLowerCase();
          const nameB = b.name.toLowerCase();

          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setBeacons(sortedBeacons);
        setIsBeaconsLoaded(true);
      } catch (err) {
        console.error(err);
      }
    }
    loadBeacons();
  }, []);

  const handleLoadAudio = async () => {
    try {
      if (!formEditData.audioURL) return;

      const response = await api.get(`/points/file/${formEditData.audioURL}`, { responseType: 'blob' });
      const audioBlob = new Blob([response.data], { type: response.headers['content-type'] });
      setAudioFile(audioBlob);
    } catch (err) {
      console.error(err);
    }
  };

  const handleLoadImage = async () => {
    try {
      if (!formEditData.imageURL) return;

      const response = await api.get(`points/file/${formEditData.imageURL}`, { responseType: 'blob' });
      const imageBlob = new Blob([response.data], { type: response.headers['content-type'] });
      setImageFile(imageBlob);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    handleLoadAudio();
    handleLoadImage();
  }, []);

  const isImageFileTypeAllowed = (file) => {
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/gif'];
    return allowedTypes.includes(file.type);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 50 * 1024 * 1024; // 50MB

    if (!file) {
      setImageFile(null);
      document.getElementById('image-input').value = '';
      return;
    }

    if (!file.type.startsWith('image/')) {
      setImageFile(null);
      document.getElementById('image-input').value = '';
      alert('Por favor, selecione um arquivo de imagem');
      return;
    }

    // Verificar se o tipo de arquivo é permitido
    if (!isImageFileTypeAllowed(file)) {
      setImageFile(null);
      document.getElementById('image-input').value = '';
      alert('Por favor, selecione um arquivo de imagem válido (jpg|jpeg|png|webp|gif).');
      return;
    }

    if (file.size > maxSizeInBytes) {
      setImageFile(null);
      document.getElementById('image-input').value = '';
      alert('Por favor, selecione um arquivo de imagem com tamanho máximo de 50mb');
      return;
    }

    setImageFile(file);
  };

  const handleImageRemoval = () => {
    setImageFile(null);
    setFormEditData({ ...formEditData, imageURL: '' });
    document.getElementById('image-input').value = '';
  };

  const isAudioFileTypeAllowed = (file) => {
    const allowedTypes = ['audio/mp3', 'audio/aac', 'audio/ogg', 'audio/wav', 'audio/aiff', 'audio/wma', 'audio/mpeg'];
    return allowedTypes.includes(file.type);
  };

  const handleAudioChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 50 * 1024 * 1024; // 50mb

    if (!file) {
      setAudioFile(null);
      document.getElementById('audio-input').value = '';
      return;
    }

    if (!file.type.startsWith('audio/')) {
      setAudioFile(null);
      document.getElementById('audio-input').value = '';
      alert('Por favor, selecione um arquivo de áudio');
      return;
    }

    // Verificar se o tipo de arquivo é permitido
    if (!isAudioFileTypeAllowed(file)) {
      setAudioFile(null);
      document.getElementById('audio-input').value = '';
      alert('Por favor, selecione um arquivo de audio válido (mp3|aac|ogg|wav|aiff|wma|mpeg).');
      return;
    }

    if (file.size > maxSizeInBytes) {
      setAudioFile(null);
      document.getElementById('audio-input').value = '';
      alert('Por favor, selecione um arquivo de áudio com tamanho máximo de 50mb');
      return;
    }

    setAudioFile(file);
  };

  const handleAudioRemoval = () => {
    setAudioFile(null);
    setFormEditData({ ...formEditData, audioURL: '' });
    document.getElementById('audio-input').value = '';
  };

  return (
    <Box sx={{ width: 450, p: 3 }}>
      <form onSubmit={handleUpdate}>
        <FormControl fullWidth sx={{ my: 1 }}>
          <InputLabel id="type-label">Tipo</InputLabel>
          <Select
            name="type"
            labelid="type-label"
            id="type-select"
            label="Tipo"
            disabled
            value={formEditData.type}
            onChange={handleInputChangeEditData}
          >
            <MenuItem value="gps">GPS</MenuItem>
            <MenuItem value="beacon">Beacon</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ my: 1 }}>
          <TextField
            name="name"
            label="Nome"
            type="text"
            value={formEditData.name}
            placeholder="Dígite um nome para o ponto"
            onChange={handleInputChangeEditData}
            required
            inputProps={{
              maxLength: 100,
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 1 }}>
          <InputLabel id="category-label">Categoria</InputLabel>
          <Select
            name="category"
            labelid="category-label"
            id="category-select"
            label="Categoria"
            disabled
            value={formEditData.categoryId}
            onChange={handleInputChangeEditData}
          >
            {categories.map((category) => (
              <MenuItem
                key={category.id}
                value={category.id}
              >
                {category.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ my: 1 }}>
          <TextField
            name="linkURL"
            label="Link URL"
            type="url"
            value={formEditData.linkURL}
            placeholder="Dígite uma URL para o link"
            helperText="Ex.: https://www.google.com.br"
            onChange={handleInputChangeEditData}
            InputProps={{
              startAdornment: (
                <InputAdornment
                  position="start"
                >
                  <LinkIcon />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 1 }}>
          <TextField
            name="linkTitle"
            label="Título para o link"
            type="text"
            value={formEditData.linkTitle}
            placeholder="Dígite um título para o link"
            helperText="Ex.: Google"
            onChange={handleInputChangeEditData}
            inputProps={{
              maxLength: 100,
            }}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 1 }}>
          <TextField
            name="description"
            label="Descrição"
            type="text"
            multiline
            rows={5}
            value={formEditData.description}
            onChange={handleInputChangeEditData}
            placeholder="Descreva do que se trata o ponto marcado"
          />
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1,
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
          }}
        >
          <Input
            name="image"
            id="image-input"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
          />
          <Button
            sx={{ mt: 1 }}
            size="large"
            variant="outlined"
            color="error"
            onClick={handleImageRemoval}
          >
            Remover
          </Button>
        </FormControl>
        {imageFile && (
          <div style={{ marginTop: '20px' }}>
            <Typography variant="body1">Imagem selecionada:</Typography>
            <img
              src={URL.createObjectURL(imageFile)}
              alt="Imagem selecionada"
              style={{ maxWidth: '100%', marginTop: '10px' }}
            />
          </div>
        )}
        {formEditData.type === 'gps' ? (
          <FormControl fullWidth sx={{ my: 1 }}>
            <TextField
              fullWidth
              label="Raio"
              variant="outlined"
              value={formEditData.radius}
              onChange={handleInputChangeEditData}
              name="radius"
              type="number"
              inputProps={{ min: 0, max: 200 }}
              required={formEditData.type === 'gps'}
            />
          </FormControl>
        ) : (
          <FormControl fullWidth sx={{ my: 1 }}>
            {isBeaconsLoaded && (
              <>
                <InputLabel id="beacon-label">Beacon</InputLabel>
                <Select
                  name="beacon"
                  labelid="beacon-label"
                  id="beacon-select"
                  label="Beacon"
                  // disabled
                  value={formEditData.beacon}
                  onChange={handleInputChangeEditData}
                >
                  {beacons.map((beacon) => (
                    <MenuItem
                      key={beacon.id}
                      value={beacon.id}
                    >
                      {beacon.name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          </FormControl>
        )}
        <FormControl fullWidth sx={{ my: 1 }}>
          <InputLabel id="visibility-label">Visibilidade</InputLabel>
          <Select
            name="isVisible"
            labelid="visibility-label"
            id="visibility-select"
            label="Visibilidade"
            value={formEditData.isVisible}
            onChange={handleInputChangeEditData}
          >
            <MenuItem value={true}>Visível</MenuItem>
            <MenuItem value={false}>Invisível</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ my: 1 }}>
          <InputLabel id="autoplay-label">Reprodução automática</InputLabel>
          <Select
            name="isAutomaticallyPlayed"
            labelid="autoplay-label"
            id="autoplay-select"
            label="Reprodução automática"
            value={formEditData.isAutomaticallyPlayed}
            onChange={handleInputChangeEditData}
          >
            <MenuItem value={true}>Sim</MenuItem>
            <MenuItem value={false}>Não</MenuItem>
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          sx={{
            my: 1,
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
          }}
        >
          <Input
            name="audio"
            id="audio-input"
            type="file"
            accept="audio/*"
            onChange={handleAudioChange}
          />
          <Button
            sx={{ mt: 1 }}
            size="large"
            variant="outlined"
            color="error"
            onClick={handleAudioRemoval}
          >
            Remover
          </Button>
        </FormControl>
        {audioFile && (
          <div style={{ marginTop: '20px' }}>
            <Typography variant="body1">Áudio selecionado:</Typography>
            <audio controls src={URL.createObjectURL(audioFile)} style={{ width: '100%' }}>
              <track kind="captions" />
            </audio>
          </div>
        )}
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          my: 1,
        }}
        >
          <Button
            sx={{
              width: 200,
            }}
            variant="contained"
            color="success"
            type="submit"
          >
            Salvar
          </Button>
          <Button
            sx={{
              my: 1,
              width: 200,
            }}
            variant="contained"
            color="warning"
            onClick={() => {
              setAudioFile(null);
              setImageFile(null);
              setSelectedPoint(null);
              setFormEditData({
                id: '',
                name: '',
                categoryId: '',
                category: '',
                description: '',
                type: 'gps',
                radius: 10,
                beacon: '',
                isVisible: true,
                isAutomaticallyPlayed: true,
                audioURL: '',
                imageURL: '',
                linkURL: '',
                linkTitle: '',
              });
            }}
          >
            Cancelar
          </Button>
        </Box>
      </form>
    </Box>
  );
}

PointUpdateWindow.propTypes = {
  selectedPoint: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    radius: PropTypes.number,
    isVisible: PropTypes.bool,
    isAutomaticallyPlayed: PropTypes.bool,
    audioURL: PropTypes.string,
    imageURL: PropTypes.string,
    linkURL: PropTypes.string,
    linkTitle: PropTypes.string,
  }).isRequired,
  setSelectedPoint: PropTypes.func.isRequired,
  formEditData: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    beacon: PropTypes.string,
    categoryId: PropTypes.string,
    radius: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
    isVisible: PropTypes.bool,
    isAutomaticallyPlayed: PropTypes.bool,
    audioURL: PropTypes.string,
    imageURL: PropTypes.string,
    linkURL: PropTypes.string,
    linkTitle: PropTypes.string,
  }).isRequired,
  setFormEditData: PropTypes.func.isRequired,
  audioFile: PropTypes.oneOfType([
    PropTypes.instanceOf(Blob),
    PropTypes.instanceOf(File),
  ]),
  setAudioFile: PropTypes.func.isRequired,
  imageFile: PropTypes.oneOfType([
    PropTypes.instanceOf(Blob),
    PropTypes.instanceOf(File),
  ]),
  setImageFile: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.object.isRequired,
  ).isRequired,
  handleUpdate: PropTypes.func.isRequired,
  handleInputChangeEditData: PropTypes.func.isRequired,
};
