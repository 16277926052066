import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FiLogIn } from 'react-icons/fi';

// ** MUI Components
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';

import illustration from '../../assets/location-concept-light.svg';
import api from '../../services/api';
import './styles.css';

export default function UserEdit() {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState({
    id: location.state.user.id,
    name: location.state.user.name,
    email: location.state.user.email,
  });

  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  function checkAuth() {
    const isLogged = localStorage.getItem('token');
    if (!isLogged) navigate('/');
  }

  useEffect(() => {
    checkAuth();
  }, []);

  const handleInputDataChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  async function handleUpdate(e) {
    e.preventDefault();
    const data = {
      name: user.name,
      email: user.email,
      // Caso seja definido uma nova senha, inclue ela no objeto data
      ...(newPassword && { password: newPassword }),
    };
    try {
      if (confirmNewPassword !== newPassword) {
        alert('Senhas não são iguais!');
        return;
      }

      await api.patch(`/users/${user.id}`, data);
      alert('Usuário atualizado com sucesso!');
      navigate('/users');
    } catch (err) {
      console.error(err);
      if (err.response.status === 500) {
        alert(
          'Falha ao cadastrar usuário, tente novamente mais tarde!',
        );
      } else if (err.response.status === 400) {
        alert('Senha inválida, a senha precisa ter:\n- Pelo menos uma letra maiúscula e uma minúscula;\n- Pelo menos um número e um caractere especial.');
      }
    }
  }

  return (
    <div className="register-container">
      <main className="content">
        <section>
          <img src={illustration} alt="Illustration" />
        </section>
        <form onSubmit={handleUpdate}>
          <h1>Editar Usuário</h1>
          <div className="icons" />
          <FormControl
            fullWidth
            sx={{
              my: 1,
            }}
          >
            <TextField
              label="Nome"
              type="text"
              name="name"
              value={user.name}
              placeholder="Digite seu nome"
              onChange={(e) => handleInputDataChange(e)}
              required
            />
          </FormControl>
          <FormControl
            fullWidth
            sx={{
              my: 1,
            }}
          >
            <TextField
              label="E-mail"
              type="email"
              name="email"
              value={user.email}
              placeholder="Digite seu e-mail"
              onChange={(e) => handleInputDataChange(e)}
              required
            />
          </FormControl>
          <FormControl
            fullWidth
            sx={{
              my: 1,
            }}
          >
            <TextField
              label="Senha"
              type="password"
              name="newPassword"
              value={newPassword}
              placeholder="Digite uma nova senha"
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </FormControl>
          <FormControl
            fullWidth
            sx={{
              my: 1,
            }}
          >
            <TextField
              label="Confirmar senha"
              type="password"
              name="confirmNewPassword"
              value={confirmNewPassword}
              placeholder="Confirme a nova senha"
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </FormControl>
          <Button
            sx={{
              mt: 1,
              width: '100%',
              height: 60,
            }}
            type="submit"
            size="large"
            variant="contained"
          >
            Confirmar
          </Button>
          <div className="back-button">
            <Link className="back-link" to="/users">
              <FiLogIn size={20} color="#414141" />
              Voltar para home
            </Link>
          </div>
        </form>
      </main>
    </div>
  );
}
