// eslint-disable-next-line import/prefer-default-export
export const mapOptions = {
  // zoomControl: false,
  // streetViewControl: false,
  // fullscreenControl: false,
  // mapTypeControl: false,
  // styles: [
  //   {
  //     featureType: 'poi',
  //     elementType: 'labels',
  //     stylers: [
  //       {
  //         visibility: 'off',
  //       },
  //     ],
  //   },
  //   {
  //     featureType: 'transit.station.bus',
  //     stylers: [
  //       {
  //         visibility: 'off',
  //       },
  //     ],
  //   },
  //   {
  //     featureType: 'transit.station.rail',
  //     stylers: [
  //       {
  //         visibility: 'off',
  //       },
  //     ],
  //   },
  // ],
};
